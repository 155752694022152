//import { PDFDocument, PDFDocumentWriter } from 'pdf-lib';

// async function mergeBetweenPDF(pdfFileList) {
// 	console.log(pdfFileList,"pdfFileList");
// let returnObj = { pdfFile: null, pdfNotMergedList: [] }

//     if (pdfFileList.length > 0) {
// 		console.log("123%%%%%%%%%%%%%%%%%%%%%%%%");
// 		const reader = new FileReader();
// 		console.log(reader,"reader")
// 		reader.onload = function(evt) { 
// 		console.log(evt.target.result,"target",evt,"evt",evt.target); 
// 		};
// 		const pdfDoc = await PDFDocument.create();
// 		console.log(pdfDoc,"pdf doc");
// 		let iterPdfDoc = await PDFDocument.create();

// 		// let iterPdfDocBytes;
// 		let pdfFilePromiseArrayBufferList = pdfFileList.map(item=>item.data);
// 		let pdfNotMergedList = [];
//         let iterPdfDocPages = [];

// 		return Promise
// 			.all(pdfFilePromiseArrayBufferList)
// 			.then((pdfArrayBufferFileList) => {
// 				for (let i = 0; i < pdfArrayBufferFileList.length; i++) {
// 					console.log(pdfArrayBufferFileList[i],"abcd data");
// 					// iterPdfDocBytes = reader.readAsArrayBuffer(pdfBlob)
// 					try {
// 						iterPdfDoc = PDFDocument.load(new Uint8Array(pdfArrayBufferFileList[i]))
// 						iterPdfDocPages = iterPdfDoc.getPages()
// 						iterPdfDoc =  PDFDocument.create();
// 						// Add each page in a temp file to check if all pages from this PDF can be added in the final one
// 						iterPdfDocPages.forEach((pdfPage) => {
// 							iterPdfDoc.addPage(pdfPage)
// 						})
// 						// No errors? Then add all pages to the final PDF
// 						iterPdfDocPages.forEach((pdfPage) => {
// 							pdfDoc.addPage(pdfPage)
// 						})
// 					} catch (err) {
// 						console.log(err)
// 						// console.log("File " + pdfFileList[i].name + " not merged due to the following error: " + err.message)
// 						pdfNotMergedList.push(pdfFileList[i].name)
// 					}
// 				}

// 				returnObj.pdfFile = PDFDocumentWriter.saveToBytes(pdfDoc)
// 				returnObj.pdfNotMergedList = pdfNotMergedList
// 				return returnObj
// 			})
// 			.catch((err) => {
// 				console.log(err)
// 				returnObj.pdfFile = null
// 				returnObj.pdfNotMergedList = pdfNotMergedList
// 				return returnObj
// 			})
//     }
// }


// ---------------------------------- modified --------------------------------------------


import { PDFDocument } from 'pdf-lib';

async function mergeBetweenPDF(pdfFileList) {
  console.log(pdfFileList, "pdfFileList");
  let returnObj = { pdfFile: null, pdfNotMergedList: [] };

  if (pdfFileList.length > 0) {
    console.log("123%%%%%%%%%%%%%%%%%%%%%%%%");

    const pdfDoc = await PDFDocument.create();
    console.log(pdfDoc, "pdf doc");

    let pdfFilePromiseArrayBufferList = pdfFileList.map(item => {
      console.log("item:::::::::", item);
      return item.data;
    });
    let pdfNotMergedList = [];

    try {
      const pdfArrayBufferFileList = await Promise.all(pdfFilePromiseArrayBufferList);

      for (let i = 0; i < pdfArrayBufferFileList.length; i++) {
        console.log(pdfArrayBufferFileList[i], "abcd data");

        try {
          const iterPdfDoc = await PDFDocument.load(new Uint8Array(pdfArrayBufferFileList[i]));
          const iterPdfDocPages = iterPdfDoc.getPages();

          // Add each page from iterPdfDoc to the main pdfDoc
          for (let j = 0; j < iterPdfDocPages.length; j++) {
            const [copiedPage] = await pdfDoc.copyPages(iterPdfDoc, [j]);
            pdfDoc.addPage(copiedPage);
          }
        } catch (err) {
          console.log(err);
          pdfNotMergedList.push(pdfFileList[i].name);
        }
      }

      const pdfBytes = await pdfDoc.save();
      returnObj.pdfFile = pdfBytes;
      returnObj.pdfNotMergedList = pdfNotMergedList;
    } catch (err) {
      console.log(err);
      returnObj.pdfFile = null;
      returnObj.pdfNotMergedList = pdfNotMergedList;
    }
  }

  return returnObj;
}

  

export default {
	mergeBetweenPDF
}
