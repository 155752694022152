import React from 'react';
import {Container,Tooltip,FormLabel,Grid,Tabs,Tab,Box,Typography,Switch,Paper,Chip,TextField,Button,Snackbar} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert'
import BankDetails from './BankDetails/BankDetails';
import WarehouseDetails from './WarehouseDetails/WarehouseDetails';
import ErrorIcon from '@material-ui/icons/Error';
import ContactInfo from './ContactInfo/ContactInfo.js';

const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

function TabPanel(props) {
    const { children, value, index, classes, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        className={classes.rightPanel}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

function myaccount(){
    const {classes} = this.props;
    return (
        this.state.blankResponse
                ?
            <div style={{textAlign:'center',paddingTop:100}}>
                <ErrorIcon 
                    style={{fontSize:150,color:'#999'}}
                />
                <h1 style={{color:"#666"}}>Oops !</h1>
                <h2 style={{color:"#666"}}>Something Went wrong</h2>
                <Button variant="contained" color="primary" onClick={this.refreshPage}>
                    Refresh
                </Button>
            </div>
                :
        <React.Fragment>
            <Container className={classes.conts}>
                {
                    this.state.isLoading
                            ?
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Skeleton variant="rect" height={200} style={{borderRadius:5,marginTop:20}} />
                        </Grid>
                        <Grid item xs={8}>
                            <Skeleton variant="rect" height={300} style={{borderRadius:5,marginTop:20}} />        
                        </Grid>
                    </Grid>
                            :
                    <div className={classes.root}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={this.state.value}
                            onChange={this.handleTabChange}
                            className={classes.leftTab}
                            indicatorColor="primary"
                            classes={{
                                indicator:classes.indicator,
                                root:classes.scrollButtons
                            }}
                        >
                            <Tab label="User Details" classes={{root:classes.rootButton,wrapper:classes.wrap,selected:classes.selectedTab}} {...a11yProps(0)}/>
                            <Tab label="Warehouse Details" classes={{root:classes.rootButton,wrapper:classes.wrap,selected:classes.selectedTab}} {...a11yProps(1)}/>
                            <Tab label="Bank Account Details" classes={{root:classes.rootButton,wrapper:classes.wrap,selected:classes.selectedTab}} {...a11yProps(2)}/>
                            <Tab label="Notification Details" classes={{root:classes.rootButton,wrapper:classes.wrap,selected:classes.selectedTab}} {...a11yProps(3)} />
                            <Tab label="Contact Info" classes={{root:classes.rootButton,wrapper:classes.wrap,selected:classes.selectedTab}} {...a11yProps(4)} />
                        </Tabs>
                        
                        <TabPanel value={this.state.value} index={0} classes={classes}>
                        <h1 className={classes.heading}>User Details</h1>
                                 <ul className={classes.user_details_u}>
                                     <li className={classes.user_details_l}>
                                         <FormLabel>Name : </FormLabel>
                                         <span>{this.state.vendor_name}</span>
                                     </li>
                                     <li className={classes.user_details_l}>
                                         <FormLabel>Email : </FormLabel>
                                         <span>{this.state.vendor_email}</span>
                                     </li>
                                     <li className={classes.user_details_l}>
                                         <FormLabel>Mobile Number : </FormLabel>
                                         <span>{"+91 "+this.state.vendor_telephone}</span>
                                     </li>
                                 </ul>  
                        </TabPanel>
                        <TabPanel value={this.state.value} index={1} classes={classes}>
                            {/* <h1 className={classes.heading}>Warehouse Address</h1> */}
                                <WarehouseDetails 
                                    address={this.state.address}
                                    resendOTP={this.resendOTP}
                                    isLoadingAddress={this.state.isLoadingAddress}
                                    getWarehouseAddress={this.getWarehouseAddress}
                                />

                        </TabPanel>
                        <TabPanel value={this.state.value} index={2} classes={classes} className={classes.tabPanel}>
                            <h1 className={classes.heading}>Bank Account Details</h1>
                            
                            <BankDetails 
                                name={this.state.name}
                                account_no={this.state.account_no}
                                ifsc={this.state.ifsc}
                                branch={this.state.branch}
                                bank_name={this.state.bank_name}
                                bank_state={this.state.bank_state}
                                bank_city = {this.state.bank_city}
                                openDialog = {this.openDialog}
                                mobile={this.state.mobile}
                                resendOTP={this.resendOTP}
                                cheque_image={this.state.cheque_image}
                                setImage={this.setImage}
                                />
                            
                        </TabPanel>
                        <TabPanel value={this.state.value} index={3} classes={classes} className={classes.tabPanel}>
                            <h1 className={classes.heading}>Notifications</h1>
                            <Paper>
                            <Typography className={classes.notificationPaper}>Turn Notification(on/off)</Typography>
                            <Switch 
                                onChange={this.setNotification}
                                checked
                                name="notificationSwitcher"
                                color="primary"
                                checked={this.state.ToggleNotification}
                            />
                            </Paper>
                            <Paper style={{marginTop:20,padding:20}}>
                                <Typography style={{textAlign:'left'}}> List of Email Ids</Typography>
                                <div style={{border:'1px solid #ccc',padding:20}}>
                                    { this.state.emailList.length > 0 ? this.state.emailList.map(item=>{
                                        return <Chip 
                                                key={item}
                                                label={item} 
                                                variant="outlined"
                                                style={{marginRight:5,marginBottom:5}}
                                                onDelete={()=>this.handleDelete(item)} 
                                                color="primary"
                                                disabled={!this.state.ToggleNotification}
                                                />
                                    })
                                :
                                null
                                }
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        style={{marginTop:10}}
                                        onClick={this.saveEmail}
                                        disabled={!this.state.ToggleNotification || !this.state.emailList.length > 0}
                                    >
                                        Save
                                    </Button>
                                </div>
                                <Paper className={classes.addform}>
                                    <FormLabel>Add Email Id</FormLabel>
                                <form  onSubmit={this.addId}>
                                    <TextField 
                                        variant="outlined" 
                                        label="email" 
                                        margin="dense"
                                        name="newEmail"
                                        style={{marginTop:0}}
                                        value={this.state.newEmail}
                                        onChange={this.handleChange}
                                        error={this.state.submitEmail && (this.state.newEmail.length<1 || !regex.test(this.state.newEmail))}
                                        helperText={this.state.submitEmail && (this.state.newEmail.length < 1 || !regex.test(this.state.newEmail)) ? "Enter valid Email Id" : ""}
                                        disabled={!this.state.ToggleNotification}
                                        />
                                    <Button 
                                        color="primary" 
                                        type="submit" 
                                        variant="contained"
                                        style={{marginLeft:10}}
                                        disabled={!this.state.ToggleNotification}
                                        >Add</Button>
                                </form>
                                </Paper>
                            </Paper>
                        </TabPanel>
                        <TabPanel value={this.state.value} index={4} classes={classes} className={classes.tabPanel}>
                                <ContactInfo />
                        </TabPanel>
                    </div>
                }  
            </Container>
            <Snackbar open={this.state.notificationOpen} anchorOrigin={{vertical:'top',horizontal:'center'}}>
                <Alert severity={this.state.notificationError ? 'error':'success'}>
                    {this.state.notificationMessage}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}
export default myaccount;